
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmAlert from '@/components/shared/TmAlert.vue'

export default defineComponent({
  components: { TmAlert, TmButton, TmModal, TmFormLine },
  setup() {
    const username = ref('AC1d762f22a6a055f56b3caa3add57a353')
    const password = ref('da983ac81882b8ebbdbb4108f5570ef5')
    const loading = ref(false)
    const isError = ref(false)
    const submitBtn = (func: () => void) => {
      loading.value = true
      setTimeout(() => {
        if (isError.value) {
          func()
        } else {
          isError.value = true
        }
        loading.value = false
      }, 1000)
    }
    return {
      username,
      password,
      submitBtn,
      isError,
      loading,
    }
  },
})
